import React from 'react';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import { PropTypes } from 'prop-types';

const ListItem = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
`;

const Inner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: white;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    width: 0; 
    height: 0;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-left: 1.5rem solid black;
  }
`;

function FeedCard( { post, id, setActiveSlide, showHandler } ) {
    return (
      <ListItem
        onClick={() => {
          showHandler();
          setActiveSlide( id );
        }}>
        <Inner>
        {post.imageVideo.file && post.imageVideo.file.contentType.includes( 'video' ) && <>
          <video width="100%" height="100%">
            <source src={post.imageVideo.file.url} type={post.imageVideo.file.contentType}/>
            Your browser does not support the video tag.
          </video>
          <PlayButton/>
        </>}

        {post.imageVideo.fluid && <GatsbyImage
          style={{
            height: '100%',
            width: '100%',
          }}
          loading="eager"
          fluid={post.imageVideo.fluid} />}
        </Inner>
      </ListItem>
    )
}

FeedCard.propTypes = {
  post: PropTypes.object.isRequired
};

export default FeedCard;
