import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/molecules/SEO';
import FeedCard from '../components/molecules/FeedCard';
import SlideShowItem from '../components/molecules/SlideShowItem';
import SlideShow from '../components/organisms/SlideShow';

const Holder = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media ( ${props => props.theme.breakpoints.md} ) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media ( ${props => props.theme.breakpoints.lg} ) {
      grid-template-columns: repeat(4, 1fr);
    }
`;

function Feed( { data, pageContext } ) {
  const [ showSlideshow, setShowSlideshow ] = useState( false );
  const [ activeSlide, setActiveSlide ] = useState( 0 );

  const items = data.allContentfulFeedPost.edges;
  return (
    <>
      <SEO title="Feed"/>
      <div className="content-holder">
        <article>
          <Holder>
            {items.map( ( edge, i ) => (
              <FeedCard
                key={edge.node.id}
                id={i}
                post={edge.node}
                setActiveSlide={( i ) => setActiveSlide( i )}
                showHandler={() => setShowSlideshow( true )}/>
            ) )}
          </Holder>
        </article>
        <SlideShow
          title=""
          show={showSlideshow}
          active={activeSlide}
          setActiveSlide={( i ) => setActiveSlide( i )}
          closeHandler={() => setShowSlideshow( false )}>
          {items.map( ( item, i ) => (
            <SlideShowItem key={i} item={item.node.imageVideo}/>
          ) )}
        </SlideShow>
      </div>
    </>
  )
}

export default Feed;

export const feedQuery = graphql`
    query feedQuery($skip: Int!, $limit: Int!) {
        allContentfulFeedPost (
            sort: { order: [ASC, DESC], fields: [order, createdAt] }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    title
                    imageVideo {
                        fluid(maxWidth: 1000) {
                            aspectRatio
                            ...GatsbyContentfulFluid_withWebp
                        }
                        file {
                            contentType
                            url
                        }
                    }
                }
            }
        }
    }
`;
